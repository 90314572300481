import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import '../../../assets/css/footer.css';
import {Event} from '../../GAClickEvent';
import facebookIcon from '../../../assets/images/header-footer/social/facebook-icon.svg';
import twitterIcon from '../../../assets/images/header-footer/social/twitter-icon.svg';
import linkedInIcon from '../../../assets/images/header-footer/social/linkedin-icon.svg';

const $ = typeof window !== `undefined` ? require('jquery') : null;

const backToTop = e => {
  e.preventDefault();
  window.scrollTo({top: 0, behavior: 'smooth'});
};

const Footer = ({showPortfolioSection, showContactSection}) => (
  <footer className="footer">
    {showPortfolioSection ? (
      <div id="initial-section" className="initial-section">
        <div className="container">
          <div className="row content-row">
            <div className="col-12 mb-4 mb-md-0 col-md-8">
              <div className="heading latoBlack">We have worked with some great clients!</div>
            </div>
            <div className="col-12 col-md-4">
              <Link
                onClick={() =>
                  Event('See Our Portfolio', 'See Our Portfolio button is clicked', 'footer')
                }
                to="/portfolio"
                className="portfolio-link btn btn-outline-light-footer latoRegular px-lg-5"
                style={{border: '1px solid white'}}
              >
                See Our Portfolio
              </Link>
            </div>
          </div>
        </div>
      </div>
    ) : null}
    {showContactSection ? (
      <div id="signup-section" className="pt-4 py-4 signup-section">
        <div className="container">
          <div className="form-row center-property">
            <div className="col-md-4">
              <Link
                className="btn btn-block btn-success-foot signup-btn"
                onClick={() =>
                  Event("Let's Get Started", "Let's Get Started button is clicked", 'footer')
                }
                to="/contact"
              >
                Let's Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    ) : null}

    <div className="address-section">
      <div className="container">
        <div className="row justify-content-center text-align-center py-5">
          <div className="col-12 mt-5">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="latoBold heading text-white mb-3">Addresses</div>
                <ul>
                  <li className="city">
                    <Link
                      to="/location/usa-office-raleigh"
                      className="city"
                      onClick={() =>
                        Event(
                          'Raleigh Office',
                          'Raleigh office location button is clicked',
                          'Page Footer'
                        )
                      }
                    >
                      <address>
                        <span className="latoRegular">Raleigh,</span>&nbsp;
                        <span className="latoBold">USA</span>
                      </address>
                    </Link>
                  </li>
                  <li className="city">
                    <Link
                      to="/location/usa-office-california"
                      className="city"
                      onClick={() =>
                        Event(
                          'California Office',
                          'California office location button is clicked',
                          'Page Footer'
                        )
                      }
                    >
                      <address>
                        <span className="latoRegular">California,</span>&nbsp;
                        <span className="latoBold">USA</span>
                      </address>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/location/canada-office"
                      className="city"
                      onClick={() =>
                        Event(
                          'Toronto Office',
                          'Toronto office location button is clicked',
                          'Page Footer'
                        )
                      }
                    >
                      <address>
                        <span className="latoRegular">Toronto,</span>&nbsp;
                        <span className="latoBold">Canada</span>
                      </address>
                    </Link>
                  </li>
                  <li className="city">
                    <Link
                      to="/location/lahore-office"
                      className="city"
                      onClick={() =>
                        Event(
                          'Lahore Office',
                          'Lahore office location button is clicked',
                          'Page Footer'
                        )
                      }
                    >
                      <address>
                        <span className="latoRegular">Lahore,</span>&nbsp;
                        <span className="latoBold">Pakistan</span>
                      </address>
                    </Link>
                  </li>
                  <li className="city">
                    <Link
                      to="/location/islamabad-office"
                      className="city"
                      onClick={() =>
                        Event(
                          'Islamabad Office',
                          'Islamabad office location button is clicked',
                          'Page Footer'
                        )
                      }
                    >
                      <address>
                        <span className="latoRegular">Islamabad,</span>&nbsp;
                        <span className="latoBold">Pakistan</span>
                      </address>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer_address social-icons">
                <div className="latoBold heading text-white mb-3">Social</div>
                <div className="footer__social-links-container">
                  <a href="https://facebook.com/Tintash" target="_blank">
                    <img src={facebookIcon} alt="Tintash facebook" />
                  </a>

                  <a href="https://twitter.com/TintashApps" target="_blank">
                    <img src={twitterIcon} alt="Tintash twitter" />
                  </a>

                  <a href="https://www.linkedin.com/company/241494" target="_blank">
                    <img src={linkedInIcon} alt="Tintash linkedin" />
                  </a>
                  <a className="footer__socials-link" href="mailto:bd@tintash.com">
                    <i
                      className="fa fa-envelope footer__socials-link-icon"
                      aria-label="Tintash Email"
                    />
                  </a>
                </div>
                <br />

                <a
                  onClick={() =>
                    Event('Dial Phone Number', 'Dial Phone Number button is clicked', 'Header')
                  }
                  className="nav-link nav-link-text pl-0"
                  style={{color: '#0e99ff'}}
                  href="tel:+14152340744"
                >
                  <i className="fa fa-rotate-90 fa-phone fa-xs"></i>
                  &nbsp; +1 (415) 234-0744
                </a>
              </div>

              <div className="col-lg-4 col-md-6 footer_address social-icons">
                <div className="latoBold heading text-white mb-3">Links</div>
                <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                  <div className="city" style={{ marginBottom: "4px" }}>
                    <Link
                      to="/privacy"
                      className="city"
                    >
                      <span className="latoRegular">Privacy Policy</span>&nbsp;
                    </Link>
                  </div>

                  <div className="city">
                    <Link
                      to="/fraud-disclaimer"
                      className="city"
                    >
                      <span className="latoRegular">Fraud Disclaimer</span>&nbsp;
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bottom-bar clearfix">
      <div className="container h-100 py-1">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-sm-8 col-12">
            <p className="latoBold mb-1 copy-right">Copyright 2022 Tintash - All Rights Reserved</p>
          </div>

          <div className="col-sm-4 col-12">
            <button
              type="submit"
              id="Top"
              className="latoBold text-white mb-0 back-top border-0 bg-transparent"
              onClick={backToTop}
            >
              Back To Top
            </button>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  showPortfolioSection: PropTypes.bool,
  showContactSection: PropTypes.bool,
};

Footer.defaultProps = {
  showPortfolioSection: true,
  showContactSection: true,
};

export default Footer;
